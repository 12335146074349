import IMask from 'imask';

export default () => {
	const modal = document.querySelector('.modal');
	const buttons = document.querySelectorAll('[data-open-popup]');
	const LOADING_CLASS = 'is-loading'
	const THANK_CLASS = 'is-thank'

	// if (modal && buttons.length) {
	// 	buttons.forEach(function(button) {
	// 		button.addEventListener('click', (e) => {
	// 			e.preventDefault();
	// 			// const fieldProduct = document.querySelector('.js-order-type');

	// 			// console.log({fieldProduct});

	// 			// if (fieldProduct) {
	// 			// 	fieldProduct.value = button.dataset.product;
	// 			// }

	// 			modal.classList.add('modal--visible');
	// 		});
	// 	});

	// 	const closeBtns = modal.querySelectorAll('.js-modal-close');
	// 	closeBtns.forEach(function(btn) {
	// 		btn.addEventListener('click', (e) => {
	// 			e.preventDefault();

	// 			modal.classList.remove('modal--visible');
	// 		});
	// 	});
	// }



	// form
	const form = document.getElementById("form");

	if (form) {
		form.addEventListener("submit", function (e) {
			e.preventDefault();
			
			const formData = new FormData(form);
			const object = Object.fromEntries(formData);
			const json = JSON.stringify(object);

			form.classList.add(LOADING_CLASS);
			
			// result.innerHTML = "Please wait...";

			fetch("https://api.web3forms.com/submit", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: json,
			})
				.then(async (response) => {
					let json = await response.json();

					form.classList.remove(LOADING_CLASS);

					if (response.status == 200) {
						form.classList.add(THANK_CLASS);

						if (typeof gtag == 'function') {
							gtag('event', 'lead');
							console.log('GA event sent');
						}
						if (typeof fbq == 'function') {
							fbq('track', 'Purchase');
							console.log('FB event sent');
						}
						
						// result.innerHTML = json.message;
					} else {
						console.log(response);
						// result.innerHTML = json.message;
					}
				})
				.catch((error) => {
					console.log(error);
					form.classList.remove(LOADING_CLASS);
					// result.innerHTML = "Something went wrong!";
				})
				.then(function () {
					form.reset();

					form.classList.remove(LOADING_CLASS);

					setTimeout(() => {
						form.classList.remove(THANK_CLASS);
						// result.style.display = "none";
					}, 5000);
				});
		});
	}


	// input mask
	const input = document.querySelector('.js-phone-mask');
	// console.log({input});

	if (input) {
		const maskOptions = {
			mask: '+{38} {(}000{)} 000-00-00',
			lazy: true
		};

		const mask = IMask(input, maskOptions);
	}
}
