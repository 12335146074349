import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default () => {
	let hideSections = document.querySelectorAll('.hero, .order');
	const bar = document.querySelector('.bar');
	const CLASS_HIDDEN = 'bar--hidden';

	// console.log({hideSections});

	if (hideSections.length && bar) {
		hideSections.forEach(function(section) {
			ScrollTrigger.create({
				trigger: section,
				onEnter: () => {
					// console.log('onEnter');
					setState(bar, false)
				},
				onEnterBack: () => {
					// console.log('onEnterBack');
					setState(bar, false)
				},
				onLeave: () => {
					// console.log('onLeave');
					setState(bar, true)
				},
				onLeaveBack: () => {
					// console.log('onLeaveBack');
					setState(bar, true)
				},
			});
		});
	}


	function setState(el, state) {
		if (state) {
			el.classList.remove(CLASS_HIDDEN);
		} else {
			el.classList.add(CLASS_HIDDEN);
		}
	}
	
}
