import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// gsap.registerPlugin(ScrollTrigger);

export default () => {
	const hero = document.querySelector('.hero');
	const btn = document.querySelector('.hero__btn');

	if (hero && btn) {
		btn.addEventListener('mouseenter', (e) => {
			e.preventDefault();
		
			hero.classList.add('hero--buy');
		});
		btn.addEventListener('mouseleave', (e) => {
			e.preventDefault();
		
			hero.classList.remove('hero--buy');
		});


		hero.addEventListener('touchstart', (e) => {
			// e.preventDefault();
		
			hero.classList.add('hero--buy');
		});
		hero.addEventListener('touchend', (e) => {
			// e.preventDefault();
		
			hero.classList.remove('hero--buy');
		});

		
	}




	// paper slider

	const paper = document.querySelector('.hero .paper');
	// console.log({paper});

	if (paper) {
		let tl = gsap.timeline({repeat: -1});

		const TIME_PRINT = 14;

		tl.to(paper, {
			y: "0%",
			duration: TIME_PRINT,
			// ease: "power2.out",
			// ease: "power1.out",
			ease: "sine.out",
		}, '>+=0.3');

		tl.to(paper, {
			y: "60vh",
			duration: 1.5,
			// ease: "power2.out",
			// ease: "power1.out",
			// ease: "sine.out",
			ease: "expo.out",
		}, '>+=1.0');

	}
}
