// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// gsap.registerPlugin(ScrollTrigger);

// import setFullHeight from './helpers/setFullHeight';

import hero from './components/hero';
import product from './components/product';
import reviews from './components/reviews';
import modal from './components/modal';
import bar from './components/bar';

import reveal from './helpers/scroll-reveal';


export default () => {
	init();
	// general();

	function init() {
		hero();
		product();
		reviews();
		reveal();
		modal();
		bar();
		// headerAnimation();
		// animation();
		// hero();
		// quote();
		// insta();
		// carousel();
		// usp();
		// bar();
		// sidebar();
		// popup();
		// productSlider();
		// comp();
		
		// footer();
	}

	function general() {
		// header();
	}

};
